import React from 'react';
import {Link} from 'react-router-dom';
import {
  Container,
  Row,
  Col
} from 'react-bootstrap';
import {
  Parallax,
  Background
} from 'react-parallax';
import {
  IoCalendarOutline
} from 'react-icons/io5';
import {
  RiUserStarLine
} from 'react-icons/ri';
import {
  MdOutlineDataExploration
} from 'react-icons/md'
import CountUp from 'react-countup';
import {CarouselBootstrap} from '../components/ui/Carousel';

export const Home = () => {
  return (
    <>
      <CarouselBootstrap />
      <section id="welcome" className="py-5">
        <Container>
          <Row>
            <Col lg={6} className="text-center">
              <img
                src={require("../assets/img/fondo-video.png")}
                alt=""
                className="img-fluid"
              />
            </Col>
            <Col lg={6} className="col-12 pt-4 pt-lg-0 text-lg-left">
              <h2 className="display-4 font-weight-bold text-secondary mt-lg-n2">
                Bienvenidos a <br /><span className="text-primary">S.I. SERINCO LTDA</span>
              </h2>
              <h6 className="text-black-50">SOMOS PARTE DE TÍ</h6>
              <p>
                Somos empresa dedicada a brindar servicios integrales especializados de
                consultoría, construcción, interventoría, asesoría, estudios, diseños de
                ingeniería y geotecnia, control de calidad en las obras civiles y viales,
                servicios de laboratorio de ingeniería en las áreas de suelos y fundaciones,
                concretos, pavimentos, materiales de construcción y mecánica de
                rocas; por tanto uno de sus principales objetivos para el desarrollo de sus
                proyectos y actividades es garantizar la satisfacción del cliente,
                calidad de vida laboral y cuidado ambiental posicionándose en el mercado y
                fidelizando a sus clientes con el servicio prestado.
              </p>
              <a to={Link} href="/about" className="btn btn-primary mt-3">Leer más</a>
            </Col>
          </Row>
        </Container>
      </section>
      <Parallax
        strength={200}
        className="bg-secondary text-center py-5 help-you text-white"
      >
        <Background>
          <img
            srcSet={require("../assets/img/fondo-help-you.jpg")}
            className="w-100"
            style={{height: '627px'}}
            alt=""
          />
        </Background>
        <Container>
          <Row className="py-5">
            <Col>
              <h2 className="display-4 font-weight-bold">¿Cómo podemos ayudarte?</h2>
              <p>Ofrecemos una amplica gama de servicios.</p>
              <a to={Link} href="/services" className="btn btn-primary">Consultar</a>
            </Col>
          </Row>
        </Container >
      </Parallax>
      <section id="awards" className="py-5 text-center">
        <Container className="py-5">
          <h2 className="display-4 font-weight-bold text-secondary">Nuestra empresa en números.</h2>
          <h6 className="text-black-50">Estos son algunos datos que debes conocer.</h6>
          <Row>
            <Col className="py-4 col-6" md={4}>
              <IoCalendarOutline className="text-primary display-4 mb-2" />
              <div className="font-weight-bold text-secondary h2">
                <CountUp
                  start={0}
                  end={30}
                  delay={0}
                >
                  {({countUpRef}) => (
                    <span className="counter-years" ref={countUpRef} />
                  )}
                </CountUp>
                <h6>Años de experiencia</h6>
              </div>
              <div className="count-line"></div>
            </Col>
            <Col className="py-4 col-6" md={4}>
              <RiUserStarLine className="text-primary display-4 mb-2" />
              <div className="font-weight-bold text-secondary h2">
                <CountUp
                  start={0}
                  end={156}
                  delay={0}
                >
                  {({countUpRef}) => (
                    <span className="counter-satisfaction" ref={countUpRef} />
                  )}
                </CountUp>
                <h6>Clientes satisfechos</h6>
              </div>
              <div className="count-line"></div>
            </Col>
            <Col className="py-4 col-6" md={4}>
              <MdOutlineDataExploration className="text-primary display-4 mb-2" />
              <div className="font-weight-bold text-secondary h2">
                <CountUp
                  start={0}
                  end={100}
                  delay={0}
                >
                  {({countUpRef}) => (
                    <span className="counter-exp" ref={countUpRef} />
                  )}
                </CountUp>
                <h6>Experiencia</h6>
                <div className="count-line"></div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}
