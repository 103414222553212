import React from 'react';
import {Link, useLocation} from 'react-router-dom';
import {Link as LinkScroll} from "react-scroll";
import {
  Navbar,
  Container,
  Nav,
  NavItem
} from 'react-bootstrap';

export const NavBar = () => {
  let location = useLocation()
  const getNavLinkClass = (path) => {
    return location.pathname === path ? 'active' : '';
  };
  return (
    <Navbar variant="dark" bg="secondary" expand="md" sticky="top">
      <Container>
        <Navbar.Toggle aria-controls="principal-menu" />
        <LinkScroll
          to="contact"
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
          className="btn btn-primary order-md-1"
        >Contacto</LinkScroll>
        <Navbar.Collapse id="principal-menu">
          <Nav className="mt-3 mt-md-0">
            <NavItem className="mb-1 mb-md-0 mr-md-2">
              <Nav.Link to={Link} href='/' className={getNavLinkClass("/")}>Inicio</Nav.Link>
            </NavItem>
            <NavItem className="mb-1 mb-md-0 mr-md-2">
              <Nav.Link to={Link} href="/about" className={getNavLinkClass("/about")}>Nuestra empresa</Nav.Link>
            </NavItem>
            <NavItem className="mb-1 mb-md-0 mr-md-2">
              <Nav.Link to={Link} href="/services" className={getNavLinkClass("/services")}>Areas de servicio</Nav.Link>
            </NavItem>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}
