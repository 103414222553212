import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes
} from "react-router-dom";

import './assets/css/notfound.css'
import './assets/css/siserinco.css'
import './assets/css/all.min.css'

import {Home} from './pages/Home'
import {About} from './pages/About'
import {Services} from './pages/Services'
import {GalleryGeotechnics} from './pages/media/Geotechnics';
import {GalleryVulnerability} from './pages/media/Vulnerability';
import {GalleryPathways} from './pages/media/Pathways';
import {GalleryHydrology} from './pages/media/Hydrology';
import {GalleryBridge} from './pages/media/Bridge';
import {GalleryStudy} from './pages/media/Study';
import {GalleryLaboratory} from './pages/media/Laboratory';
import {NotFound} from './pages/NotFound';
import {ContactBar} from './components/ui/ContactBar';
import {HeaderDetails} from './components/ui/HeaderDetails';
import {NavBar} from './components/ui/Navbar';
import {Footer} from './components/ui/Footer';

export const App = () => {
  return (
    <Router>
      <ContactBar />
      <HeaderDetails />
      <NavBar />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/about/" element={<About />} />
        <Route exact path="/services/" element={<Services />} />
        <Route exact path="/gallery/study" element={<GalleryStudy />} />
        <Route exact path="/gallery/geotechnics" element={<GalleryGeotechnics />} />
        <Route exact path="/gallery/vulnerability" element={<GalleryVulnerability />} />
        <Route exact path="/gallery/pathways" element={<GalleryPathways />} />
        <Route exact path="/gallery/hydrology" element={<GalleryHydrology />} />
        <Route exact path="/gallery/bridge" element={<GalleryBridge />} />
        <Route exact path="/gallery/laboratory" element={<GalleryLaboratory />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
    </Router >
  );
}
