import React from 'react';
import {Link} from 'react-router-dom';
import {
  Container,
  Row,
  Col
} from 'react-bootstrap';
import {
  SiOpenstreetmap
} from 'react-icons/si';
import {
  CgPhone
} from 'react-icons/cg';
import {
  IoMail
} from 'react-icons/io5';
import {
  MdScheduleSend
} from 'react-icons/md';


export const Footer = () => {
  return (
    <>
      <section id="contact">
        <header className="py-5 bg-primary text-white text-center position-relative">
          <h2 className="display-4 font-weight-bold">Contáctanos</h2>
          <h6 className="text-white-50">Estamos listos para atenderte</h6>
          <div className="detail-rectangle"></div>
        </header>
        <Container>
          <Row className="py-5">
            <Col lg={6}>
              <h2 className="font-weight-bold text-center mb-2">Detalles de contacto:</h2>
              <ul className="list-unstyled">
                <li className="d-flex border-bottom py-4">
                  <SiOpenstreetmap className="fa-3x text-primary mr-4 fa-fw" />
                  <div>
                    <a
                      href="https://goo.gl/maps/TyWEhGruQbo7PHgo9"
                      style={{textDecoration: 'none', color: '#696969'}}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <h5 className="font-weight-bold">Dirección:</h5>
                      Transversal 17 o Avenida San Martín No. 20-21.
                    </a>
                  </div>
                </li>
                <li className="d-flex border-bottom py-4">
                  <CgPhone className="fa-3x text-warning mr-4 fa-fw" />
                  <div>
                    <h5 className="font-weight-bold">Teléfonos:</h5>
                    <p className="mb-0">3132846468 - 3125139693</p>
                  </div>
                </li>
                <li className="d-flex border-bottom py-4">
                  <IoMail className="fa-3x text-danger mr-4 fa-fw" />
                  <div>
                    <h5 className="font-weight-bold">Correo electrónico:</h5>
                    <p className="mb-0">siserinco@gmail.com - admon.siserinco@gmail.com</p>
                  </div>
                </li>
              </ul>
            </Col>
            <Col className="pt-4 pt-lg-0" lg={5}>
              <img
                src={require("../../assets/img/fondo-video.png")}
                alt="Logo de la empresa"
                className="img-fluid"
              />
            </Col>
          </Row>
        </Container>
      </section>
      <footer id="footer" className="bg-dark text-light text-truncate">
        <Container>
          <Row className="align-items-center">
            <Col className="inclined-column py-2 col-12" xl={5}>
              <Row>
                <Col className="col-12">
                  <h2 className="text-white font-weight-bold h1">Horario</h2>
                </Col>
                <Col className="text-black-50 col-auto">
                  <MdScheduleSend className="fa-4x" />
                </Col>
                <Col className="text-black-50 col-auto">
                  <h4>Servicio de Lunes a Viernes</h4>
                  <h4>de 8 AM a 6 PM.</h4>
                </Col>
              </Row>
            </Col>
            <Col className="py-2 text-md-right small col-12" xl={7}>
              <ul className="secondary-links list-unstyled d-inline-flex text-uppercase">
                <li><a to={Link} href="/">Inicio</a></li>
                <li><a to={Link} href="/about">Sobre nosotros</a></li>
              </ul>
              <p>&copy;2021. Todos los derechos reservados a S.I. Serinco LTDA.</p>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  )
}
