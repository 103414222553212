import React from 'react';
import {ParallaxCustom} from '../components/ui/ParallaxCustom';

export const Services = () => {
  return (
    <>
      <ParallaxCustom
        classname="study"
        content={{
          image: "fondo-slide-1-xl",
          classname: "study-media",
          title: "Estudios de suelos y fundaciones",
          paragraph: "Cumplimos mas de 30 años de experiencia en la ejecución de este tipo de estudios para edificios, y construcciones e instalaciones de todo tipo de infraestructura.",
          link: "/gallery/study/",
        }}
        height='1143px'
      />
      <div className="py-1" />
      <ParallaxCustom
        classname="apply"
        content={{
          image: 'AMENAZA3-xl',
          classname: 'apply-media',
          title: 'Geotecnia aplicada',
          paragraph: '- Estudios Geotecnicos aplicados a proyectos de infraestructura industrial, electrica, vial, ambiental y urbanistica, estabilidad de taludes, zonas inestables, estudios de amenazas, vulnerabilidad y riesgos, procesos de remoción en masa, diseño de ZODME (Geoelectrico, Geotecnico e hidraulico). - Estudios geologicos y levantamientos geofisicos aplicados a investigacion geotecnica y exploracion de aguas subterraneas.',
          link: "/gallery/geotechnics/"
        }}
        height="1220px"
      />
      <div className="py-1" />
      <ParallaxCustom
        classname="study"
        content={{
          image: 'vul-xl',
          classname: 'study-media',
          title: 'Vulnerabilidad y patología',
          paragraph: 'Somos una empresa líder en este tipo de consultoría, con amplia experiencia y trayectoria de participación y ejecución mediante concurso de méritos para construcciones institucionales en las áreas de la salud, educación infraestructura física.',
          link: "/gallery/vulnerability"
        }}
        height='1143px'
      />
      <div className="py-1" />
      <ParallaxCustom
        classname="bridge"
        content={{
          image: 'puente-xl',
          classname: 'bridge-media',
          title: 'Estudio y diseños de puentes',
          paragraph: '- Nuestra empresa tiene una amplia trayectoria de experiencia certificada en esta área, para puentes vehiculares y peatonales en concreto reforzado, pre esforzado y estructura metálica. - El paquete de estudios y diseños incluye diseño geométrico, estudios geotécnico de suelos y fundaciones, estudio hidráulico de socavación, diseño estructural.',
          link: "/gallery/bridge"
        }}
        height='1126px'
      />
      <div className="py-1" />
      <ParallaxCustom
        classname="pathways"
        content={{
          image: 'vias-xl',
          classname: 'pathways-media',
          title: 'Vías y pavimentos',
          paragraph: 'Estudios y diseños para proyectos viales e infraestructura vial (Diseño geométrico, exploración y caracterización geotécnica, diseño de drenaje y sub drenaje, estadios de transito y señalización)',
          link: "/gallery/pathways"
        }}
        height="1483px"
      />
      <div className="py-1" />
      <ParallaxCustom
        classname="water"
        content={{
          image: 'agua-xl',
          classname: 'water-media',
          title: 'Hidrología e hidraulica',
          paragraph: 'Estudios Hidrológicos e hidráulicos aplicados a corrientes hídricas, diseño de drenaje y sub drenaje en vías, obras civiles y obras geotécnicas y proyectos ambientales.',
          link: "/gallery/hydrology"
        }}
        height='1137px'
      />
      <div className="py-1" />
      <ParallaxCustom
        classname="laboratory"
        content={{
          image: 'lab-xl',
          classname: 'laboratory-media',
          title: 'Laboratorios de ingeniería y geotecnia',
          paragraph: '- Servicios de ensayos en laboratorio y obras para el control de calidad en las mismas, en las áreas siguientes: concretos, pavimentos, materiales de construcción, mecánica de rocas. - Consultoría y asesoría en el aseguramiento y control de calidad en obras civiles, vías y pavimentos',
          link: '/gallery/laboratory/'
        }}
        height="1143px"
      />
    </>
  )
}
