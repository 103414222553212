import {
  Container,
  Row,
  Col
} from 'react-bootstrap';

import {
  FaFacebookF,
  FaMapMarkerAlt
} from 'react-icons/fa'

export const ContactBar = () => {
  return (
    <section id="contact-bar" className="bg-primary text-white py-3 py-lg-2 text-center">
      <Container>
        <Row className="justify-content-sm-between align-items-sm-center">
          <Col className="col-12" sm="auto">
            <a
              href="https://goo.gl/maps/TyWEhGruQbo7PHgo9"
              style={{textDecoration: 'none', color: '#fff'}}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FaMapMarkerAlt size={16} className="icon-map" /> <span>Transversal 17 o Avenida San Martín No. 20-21</span>
            </a>
          </Col>
          <Col sm="auto">
            <ul className="redes-sociales list-unstyled d-inline-flex mb-0">
              <li><a
                href="https://www.facebook.com/SERINCOLTDA/"
                target="_blank"
                rel="noopener noreferrer"
              ><FaFacebookF size={24} /></a></li>
            </ul>
          </Col>
        </Row>
      </Container>
    </section>
  )
}
