import React from 'react';
import {
  Container,
  Row,
  Col
} from 'react-bootstrap';
import {
  Parallax,
  Background
} from 'react-parallax';
import SimpleReactLightBox, {SRLWrapper} from 'simple-react-lightbox';

import geoOne from '../../assets/img/galeria/geotecnia/folderOne/AMENAZA 3.jpg';
import geoTwo from '../../assets/img/galeria/geotecnia/folderOne/saboyav 6.png';
import geoThree from '../../assets/img/galeria/geotecnia/folderOne/chiguieca 11.png';
import geoFour from '../../assets/img/galeria/geotecnia/folderOne/chiguieca 25.png';
import geoFive from '../../assets/img/galeria/geotecnia/folderTwo/IMG_0305.JPG';
import geoSix from '../../assets/img/galeria/geotecnia/folderTwo/IMG-20201113.jpg';
import geoSeven from '../../assets/img/galeria/geotecnia/folderTwo/IMG-20201113-W.jpg';
import geoEight from '../../assets/img/galeria/geotecnia/folderTwo/GEOLOGIA RANCH 3.png';
import geoNine from '../../assets/img/galeria/geotecnia/folderThree/IMG.jpg';
import geoTen from '../../assets/img/galeria/geotecnia/folderThree/APDR 3.png';
import geoEleven from '../../assets/img/galeria/geotecnia/folderThree/P1030560.JPG';
import geoTwelve from '../../assets/img/galeria/geotecnia/folderThree/A.P.R.ESTUF.jpg';
import geoThirteen from '../../assets/img/galeria/geotecnia/folderThree/APDR ESTUF 4.jpg';
import geoFourteen from '../../assets/img/galeria/geotecnia/folderThree/industria 1.jpeg';
import techOne from '../../assets/img/galeria/geotecnia/folderFour/ubala 1.png';
import techTwo from '../../assets/img/galeria/geotecnia/folderFour/IMG_2020.jpg';
import techThree from '../../assets/img/galeria/geotecnia/folderFour/modelos 2.png';
import techFour from '../../assets/img/galeria/geotecnia/folderFour/ubala  h 2.png';
import techFive from '../../assets/img/galeria/geotecnia/folderFour/BLOQUE 3D 1.png';
import techSix from '../../assets/img/galeria/geotecnia/folderFour/IMAGEN SATEL.png';
import techSeven from '../../assets/img/galeria/geotecnia/folderFour/20161102_085641.jpg';
import techEight from '../../assets/img/galeria/geotecnia/folderFour/20161102_093532.jpg';
import techNine from '../../assets/img/galeria/geotecnia/folderFour/20170302_083737.jpg';
import nicsOne from '../../assets/img/galeria/geotecnia/folderFive/P10.JPG';
import nicsTwo from '../../assets/img/galeria/geotecnia/folderFive/MANCL.jpg';
import nicsThree from '../../assets/img/galeria/geotecnia/folderFive/geo 1.png';
import nicsFour from '../../assets/img/galeria/geotecnia/folderFive/model.png';
import nicsFive from '../../assets/img/galeria/geotecnia/folderFive/talud 1.jpg';

function ImageList(props) {
  const images = props.images;
  return (
    <>
      {images.map((image) =>
        <Col
          md={6}
          lg={3}
          className="col-12-new col-12"
        >
          <img
            src={image}
            alt={props.alt}
          />
        </Col>
      )}
    </>
  );
}

export const GalleryGeotechnics = () => {
  const images = [
    geoOne, geoTwo,
    geoThree, geoFour,
    geoFive, geoSix,
    geoSeven, geoEight,
    geoTen, geoEleven,
    geoTwelve, geoThirteen, geoFourteen,
    techOne, , techThree,
    techFive, techSix,
    techSeven, techEight,
    nicsOne, nicsTwo, nicsThree,
    nicsFour, nicsFive, techNine,
    geoNine, techTwo, techFour
  ]
  return (
    <>
      <Parallax
        strength={200}
        className="bg-secondary text-center parallax-right py-5 text-white"
      >
        <Background>
          <img
            srcSet={require("../../assets/img/title.jpg")}
            className="w-100"
            style={{height: '445px'}}
            alt=""
          />
        </Background>
        <Container>
          <Row className="py-5">
            <Col>
              <h2 className="display-4 font-weight-bold">Galeria de geotecnia aplicada</h2>
            </Col>
          </Row>
        </Container >
      </Parallax>
      <Container id="gallery">
        <SimpleReactLightBox>
          <SRLWrapper>
            <Row
              className="d-flex flex-wrap align-items-center"
            >
              <ImageList
                images={images}
              />
            </Row>
          </SRLWrapper>
        </SimpleReactLightBox>
      </Container >
    </>
  );
}
