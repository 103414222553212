import React from 'react';
import {
  Container,
  Row,
  Col
} from 'react-bootstrap';
import {
  Parallax,
  Background
} from 'react-parallax';
import SimpleReactLightBox, {SRLWrapper} from 'simple-react-lightbox';

import studyOne from '../../assets/img/galeria/estudios/FOTO 2(1).jpg';
import studyTwo from '../../assets/img/galeria/estudios/EXCAVACION.jpg';
import studyThree from '../../assets/img/galeria/estudios/eurocrane1.jpg';
import studyFour from '../../assets/img/galeria/estudios/PILOTEADORA.jpg';
import studyFive from '../../assets/img/galeria/estudios/edificacion.jpg';
import studySix from '../../assets/img/galeria/estudios/20170228_082921.jpg';
import studySeven from '../../assets/img/galeria/estudios/20181011_120415.jpg';
import studyEight from '../../assets/img/galeria/estudios/20190610_144126.jpg';
import studyNine from '../../assets/img/galeria/estudios/ubala geotecnia.png';
import studyTen from '../../assets/img/galeria/estudios/losa cimentacion.jpg';
import studyEleven from '../../assets/img/galeria/estudios/servicio-pilote-02.jpg';
import studyTwelve from '../../assets/img/galeria/estudios/2013-01-16 11.12.23.jpg';

function ImageList(props) {
  const images = props.images;
  return (
    <>
      {images.map((image) =>
        <Col
          md={6}
          lg={3}
          className="col-12-new col-12"
        >
          <img
            src={image}
            alt={props.alt}
          />
        </Col>
      )}
    </>
  );
}

export const GalleryStudy = () => {
  const images = [
    studyOne, studyTwo,
    studyFour, studyFive, studySix,
    studyEight, studyNine, studyTen,
    studyEleven, studyTwelve,
    studyThree, studySeven
  ]
  return (
    <>
      <Parallax
        strength={200}
        className="bg-secondary text-center parallax-right py-5 text-white"
      >
        <Background>
          <img
            srcSet={require("../../assets/img/title.jpg")}
            className="w-100"
            style={{height: '445px'}}
            alt=""
          />
        </Background>
        <Container>
          <Row className="py-5">
            <Col>
              <h2 className="display-4 font-weight-bold">Galeria de estudio de suelos y fundaciones.</h2>
            </Col>
          </Row>
        </Container >
      </Parallax>
      <Container id="gallery">
        <SimpleReactLightBox>
          <SRLWrapper>
            <Row
              className="d-flex flex-wrap align-items-center"
            >
              <ImageList
                images={images}
              />
            </Row>
          </SRLWrapper>
        </SimpleReactLightBox>
      </Container >
    </>
  );
}
