import React from 'react';
import {
  Container,
  Row,
  Col
} from 'react-bootstrap';
import {
  Parallax,
  Background
} from 'react-parallax';
import SimpleReactLightBox, {SRLWrapper} from 'simple-react-lightbox';

import bridOne from '../../assets/img/galeria/puentes/IMG_0299.JPG';
import bridTwo from '../../assets/img/galeria/puentes/IMG_0326.JPG';
import bridThree from '../../assets/img/galeria/puentes/puentes 1.jpg';
import bridFour from '../../assets/img/galeria/puentes/puente cwebadero.png';
import bridFive from '../../assets/img/galeria/puentes/FOTOS DUITAMA- JERICO 091.jpg';
import bridSix from '../../assets/img/galeria/puentes/FOTOS DUITAMA- JERICO 108.jpg';
import bridSeven from '../../assets/img/galeria/puentes/FOTOS DUITAMA- JERICO 136.jpg';

function ImageList(props) {
  const images = props.images;
  return (
    <>
      {images.map((image) =>
        <Col
          md={6}
          lg={3}
          className="col-12-new col-12"
        >
          <img
            src={image}
            alt={props.alt}
          />
        </Col>
      )}
    </>
  );
}

export const GalleryBridge = () => {
  const images = [
    bridOne, bridTwo,
    bridThree, bridFive, bridSix,
    bridSeven, bridFour
  ]
  return (
    <>
      <Parallax
        strength={200}
        className="bg-secondary text-center parallax-right py-5 text-white"
      >
        <Background>
          <img
            srcSet={require("../../assets/img/title.jpg")}
            className="w-100"
            style={{height: '445px'}}
            alt=""
          />
        </Background>
        <Container>
          <Row className="py-5">
            <Col>
              <h2 className="display-4 font-weight-bold">Galeria de estudio y diseños de puentes.</h2>
            </Col>
          </Row>
        </Container >
      </Parallax>
      <Container id="gallery">
        <SimpleReactLightBox>
          <SRLWrapper>
            <Row
              className="d-flex flex-wrap align-items-center"
            >
              <ImageList
                images={images}
              />
            </Row>
          </SRLWrapper>
        </SimpleReactLightBox>
      </Container >
    </>
  );
}
