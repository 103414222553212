import React from 'react';
import {
  Carousel
} from 'react-bootstrap';
import backgroundOne from "../../assets/img/fondo-slide-1.jpg";
import backgroundTwo from "../../assets/img/fondo-slide-2.jpg";
import backgroundThree from "../../assets/img/fondo-slide-3.jpg";
import backgroundFour from "../../assets/img/fondo-slide-4.jpg";
export const CarouselBootstrap = () => {
  return (
    <Carousel id="animation">
      <Carousel.Item style={{backgroundImage: `url(${backgroundFour})`}}>
        <Carousel.Caption>
          <h2 className="display-4 font-weight-bold">Más de 30 años de liderazgo en la calidad de los servicios de ingeniería.</h2>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item style={{backgroundImage: `url(${backgroundOne})`}}>
        <Carousel.Caption>
          <h2 className="display-4 font-weight-bold">Servicios para proyectos y obras hechos con calidad.</h2>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item
        className="item-two-media"
        style={{backgroundImage: `url(${backgroundTwo})`}}>
        <Carousel.Caption>
          <h2 className="display-4 font-weight-bold">
            Soluciones geotecnicas <br />y de ingeniería confiables.
          </h2>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item style={{backgroundImage: `url(${backgroundThree})`, backgroundPosition: 'center 0px'}}>
        <Carousel.Caption>
          <h2 className="display-4 font-weight-bold">
            Disponible de Lunes a Viernes de <br />8 AM a 6 PM.
          </h2>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  )
}
