import React from 'react';
import logo from '../../assets/img/logo-header.png'
import {
  Container,
  Row,
  Col
} from 'react-bootstrap'
import {
  CgPhone
} from 'react-icons/cg'
import {
  IoMail
} from 'react-icons/io5'

export const HeaderDetails = () => {
  return (
    <section id="header-details" className="d-none d-md-block py-3">
      <Container>
        <Row className="align-items-md-center justify-content-md-between">
          <Col className="col-auto">
            <img src={logo} alt="Logo de S.I. Serinco LTDA." className="img-fluid logo" />
          </Col>
          <Col className="col-auto ml-md-auto">
            <CgPhone className="io-2x align-middle text-primary" size={32} />{" "}
            <span className="font-weight-bold h5 ml-2"> 313-284-6468</span>
          </Col>
          <Col className="col-auto">
            <IoMail className="io-2x align-middle text-primary" size={32} />{" "}
            <span className="font-weight-bold h5 ml-2">siserinco@gmail.com</span>
          </Col>
        </Row>
      </Container>
    </section>
  )
}
