import React from 'react';
import {
  Container,
  Row,
  Col
} from 'react-bootstrap';
import {
  Parallax,
  Background
} from 'react-parallax';
import SimpleReactLightBox, {SRLWrapper} from 'simple-react-lightbox';

import patOne from '../../assets/img/galeria/vulnerabilidad_patologia/PATOLOGIA/DSC08815.JPG';
import patTwo from '../../assets/img/galeria/vulnerabilidad_patologia/PATOLOGIA/20180222_173746.jpg';
import patThree from '../../assets/img/galeria/vulnerabilidad_patologia/PATOLOGIA/20180623_100626.jpg';
import patFour from '../../assets/img/galeria/vulnerabilidad_patologia/PATOLOGIA/20180711_142955.jpg';
import patFive from '../../assets/img/galeria/vulnerabilidad_patologia/PATOLOGIA/20180718_092208.jpg';
import patSix from '../../assets/img/galeria/vulnerabilidad_patologia/PATOLOGIA/20180723_101240.jpg';
import patSeven from '../../assets/img/galeria/vulnerabilidad_patologia/PATOLOGIA/20180804_091743.jpg';
import patEight from '../../assets/img/galeria/vulnerabilidad_patologia/PATOLOGIA/20190606_103726.jpg';
import patNine from '../../assets/img/galeria/vulnerabilidad_patologia/PATOLOGIA/IMG_20200217_095045.jpg';
import vulOne from '../../assets/img/galeria/vulnerabilidad_patologia/vulnerabilidad/20180619_101336.jpg';
import vulTwo from '../../assets/img/galeria/vulnerabilidad_patologia/vulnerabilidad/20180716_161935.jpg';
import vulThree from '../../assets/img/galeria/vulnerabilidad_patologia/vulnerabilidad/rehabilitacion 1.png';
import vulFour from '../../assets/img/galeria/vulnerabilidad_patologia/vulnerabilidad/vulnerabilidad 1.png';
import vulFive from '../../assets/img/galeria/vulnerabilidad_patologia/vulnerabilidad/vulnerabilidad 3.png';


function ImageList(props) {
  const images = props.images;
  return (
    <>
      {images.map((image) =>
        <Col
          md={6}
          lg={3}
          className="col-12-new col-12"
        >
          <img
            src={image}
            alt={props.alt}
          />
        </Col>
      )}
    </>
  );
}

export const GalleryVulnerability = () => {
  const images = [
    patOne,
    patThree, patFour,
    patFive, patSix, patSeven,
    patEight, vulOne, vulThree,
    vulFour, vulFive,
    vulTwo, patTwo, patNine
  ]
  return (
    <>
      <Parallax
        strength={200}
        className="bg-secondary text-center parallax-right py-5 text-white"
      >
        <Background>
          <img
            srcSet={require("../../assets/img/title.jpg")}
            className="w-100"
            style={{height: '445px'}}
            alt=""
          />
        </Background>
        <Container>
          <Row className="py-5">
            <Col>
              <h2 className="display-4 font-weight-bold">Galeria de vulnerabilidades y patologias.</h2>
            </Col>
          </Row>
        </Container >
      </Parallax>
      <Container id="gallery">
        <SimpleReactLightBox>
          <SRLWrapper>
            <Row
              className="d-flex flex-wrap align-items-center"
            >
              <ImageList
                images={images}
              />
            </Row>
          </SRLWrapper>
        </SimpleReactLightBox>
      </Container >
    </>
  );
}
