import React, {
  useRef,
  useEffect
} from 'react';
import {
  Container,
} from 'react-bootstrap';
import gsap from 'gsap';

export const NotFound = () => {
  const getElementOne = useRef(null);
  const getElementTwo = useRef(null);
  const getElementThree = useRef(null);
  useEffect(() => {
    if (getElementOne) {
      gsap.to(".cog1",
        {
          transformOrigin: "50% 50%",
          rotation: "+=360",
          repeat: -1,
          ease: "none",
          duration: 8
        });
    }
  }, [getElementOne]);
  useEffect(() => {
    if (getElementTwo) {
      gsap.to(".cog2",
        {
          transformOrigin: "50% 50%",
          rotation: "-=360",
          repeat: -1,
          ease: "none",
          duration: 8,
        });
    };
  }, [getElementTwo])
  useEffect(() => {
    if (getElementThree) {
      gsap.fromTo(".wrong-para",
        {
          opacity: 0,
        },
        {
          opacity: 1,
          duration: 1,
          stagger: {
            repeat: -1,
            yoyo: true
          }
        });
    };
  }, [getElementThree])

  return (
    <>
      <Container className="container-notfound">
        <h1 className="first-four">4</h1>
        <div className="cog-wheel1">
          <div className="cog1" ref={getElementOne}>
            <div className="top" />
            <div className="down" />
            <div className="left-top" />
            <div className="left-down" />
            <div className="right-top" />
            <div className="right-down" />
            <div className="left" />
            <div className="right" />
          </div>
        </div>
        <div className="cog-wheel2">
          <div className="cog2" ref={getElementTwo}>
            <div className="top" />
            <div className="down" />
            <div className="left-top" />
            <div className="left-down" />
            <div className="right-top" />
            <div className="right-down" />
            <div className="left" />
            <div className="right" />
          </div>
        </div>
        <h1 className="second-four">4</h1>
        <p className="wrong-para" ref={getElementThree}>Uh Oh! Página no encontrada!</p>
      </Container>
    </>
  )
}
