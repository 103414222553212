import React from 'react';
import {
  Container,
  Row,
  Col
} from 'react-bootstrap';
import {
  Parallax,
  Background
} from 'react-parallax';
import SimpleReactLightBox, {SRLWrapper} from 'simple-react-lightbox';

import hydroOne from '../../assets/img/galeria/hidrologia_hidraulica/fira 1.png';
import hydroTwo from '../../assets/img/galeria/hidrologia_hidraulica/fira 6.png';
import hydroThree from '../../assets/img/galeria/hidrologia_hidraulica/fira 10.png';
import hydroFour from '../../assets/img/galeria/hidrologia_hidraulica/ubala 2.png';
import hydroFive from '../../assets/img/galeria/hidrologia_hidraulica/P1030729.JPG';
import hydroSix from '../../assets/img/galeria/hidrologia_hidraulica/ubala  h 2.png';
import hydroSeven from '../../assets/img/galeria/hidrologia_hidraulica/HIDROGEOLOGIA.png';
import hydroEight from '../../assets/img/galeria/hidrologia_hidraulica/20170304_101938.jpg';

function ImageList(props) {
  const images = props.images;
  return (
    <>
      {images.map((image) =>
        <Col
          md={6}
          lg={3}
          className="col-12-new col-12"
        >
          <img
            src={image}
            alt={props.alt}
          />
        </Col>
      )}
    </>
  );
}

export const GalleryHydrology = () => {
  const images = [
    hydroOne, hydroTwo,
    hydroThree, hydroFour,
    hydroFive, hydroSeven,
    hydroEight, hydroSix
  ]
  return (
    <>
      <Parallax
        strength={200}
        className="bg-secondary text-center parallax-right py-5 text-white"
      >
        <Background>
          <img
            srcSet={require("../../assets/img/title.jpg")}
            className="w-100"
            style={{height: '445px'}}
            alt=""
          />
        </Background>
        <Container>
          <Row className="py-5">
            <Col>
              <h2 className="display-4 font-weight-bold">Galeria hidrología e hidraulica.</h2>
            </Col>
          </Row>
        </Container >
      </Parallax>
      <Container id="gallery">
        <SimpleReactLightBox>
          <SRLWrapper>
            <Row
              className="d-flex flex-wrap align-items-center"
            >
              <ImageList
                images={images}
              />
            </Row>
          </SRLWrapper>
        </SimpleReactLightBox>
      </Container >
    </>
  );
}
