import React from 'react';
import {
  Container,
  Row,
  Col
} from 'react-bootstrap';
import {
  Parallax,
  Background
} from 'react-parallax';
import SimpleReactLightBox, {SRLWrapper} from 'simple-react-lightbox';

import laboOne from '../../assets/img/galeria/laboratorio/016.JPG';
import laboTwo from '../../assets/img/galeria/laboratorio/028.JPG';
import laboThree from '../../assets/img/galeria/laboratorio/033.JPG';
import laboFour from '../../assets/img/galeria/laboratorio/042.JPG';
import laboFive from '../../assets/img/galeria/laboratorio/046.JPG';
import laboSix from '../../assets/img/galeria/laboratorio/090.JPG';
import laboSeven from '../../assets/img/galeria/laboratorio/091.jpg';


function ImageList(props) {
  const images = props.images;
  return (
    <>
      {images.map((image) =>
        <Col
          md={6}
          lg={3}
          className="col-12-new col-12"
        >
          <img
            src={image}
            alt={props.alt}
          />
        </Col>
      )}
    </>
  );
}

export const GalleryLaboratory = () => {
  const images = [
    laboOne, laboTwo,
    laboSix, laboSeven,
    laboThree, laboFour, laboFive
  ]
  return (
    <>
      <Parallax
        strength={200}
        className="bg-secondary text-center parallax-right py-5 text-white"
      >
        <Background>
          <img
            srcSet={require("../../assets/img/title.jpg")}
            className="w-100"
            style={{height: '445px'}}
            alt=""
          />
        </Background>
        <Container>
          <Row className="py-5">
            <Col>
              <h2 className="display-4 font-weight-bold">Galeria de laboratorios de ingeniería y geotecnia.</h2>
            </Col>
          </Row>
        </Container >
      </Parallax>
      <Container id="gallery">
        <SimpleReactLightBox>
          <SRLWrapper>
            <Row
              className="d-flex flex-wrap align-items-center"
            >
              <ImageList
                images={images}
              />
            </Row>
          </SRLWrapper>
        </SimpleReactLightBox>
      </Container >
    </>
  );
}
