import React from 'react';
import {
  Container,
  Row,
  Col
} from 'react-bootstrap';

export const About = () => {
  return (
    <>
      <section id="welcome" className="py-5">
        <Container>
          <Row>
            <Col className="pt-4 pt-lg-0 text-center text-lg-left col-12" lg={4}>
              <h2 className="display-4 font-weight-bold text-secondary mt-lg-n2">
                <br /><span className="text-primary">Visión</span>
              </h2>
              <h6 className="text-black-50">S.I. Serinco LTDA</h6>
              <p>
                Proyecta consolidar el liderazgo alcanzado en el departamento de Boyacá y la
                región, posicionándose a nivel nacional con altos estándares de competitividad; como empresa
                prestadora de servicios especializados en las áreas de Ingeniería, Geotecnia y aseguramiento
                de la calidad en obras civiles y viales, manejando altos estándares de calidad y excelencia en el
                servicio al cliente.
              </p>
            </Col>
            <Col className="text-center col-12" lg={4}>
              <img
                src={require("../assets/img/ESCUDOBL.png")}
                alt="Aparece un escudo con las siguientes palabras: Ingeniería, Geotecnia, Servicio. Con el nombre S.I. Serinco LTDA que significa: Servicios integrales de ingeniería, geotecnia y construcción."
                className="img-fluid"
              />
            </Col>
            <Col className="pt-4 pt-lg-0 text-center text-lg-left col-12" lg={4}>
              <h2 className="display-4 font-weight-bold text-secondary mt-lg-n2">
                <br /><span className="text-primary">Misión</span>
              </h2>
              <h6 className="text-black-50">S.I. Serinco LTDA</h6>
              <p>
                Presta servicios integrales especializados de consultoría, estudios y diseños de Geotecnia e
                Ingeniería, control de calidad en obras civiles y viales, servicios de laboratorio de Ingeniería
                en las áreas de suelos, concretos, pavimentos, materiales de construcción y mecánica de rocas:
                satisfaciendo los requerimientos acordes con los avances tecnológicos y de normalización vigente.
              </p>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  )
}
