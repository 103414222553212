import React from 'react';
import {
  Container,
  Row,
  Col
} from 'react-bootstrap';
import {
  Parallax,
  Background
} from 'react-parallax';
import SimpleReactLightBox, {SRLWrapper} from 'simple-react-lightbox';

import pathOne from '../../assets/img/galeria/vias_pavimentos/vias 1.jpeg';
import pathTwo from '../../assets/img/galeria/vias_pavimentos/DSC09059.JPG';
import pathThree from '../../assets/img/galeria/vias_pavimentos/DSC09916.JPG';
import pathFour from '../../assets/img/galeria/vias_pavimentos/chameza via 1.png';
import pathFive from '../../assets/img/galeria/vias_pavimentos/20161025_153809.jpg';
import pathSix from '../../assets/img/galeria/vias_pavimentos/IMG_20200608_085636.jpg';
import pathSeven from '../../assets/img/galeria/vias_pavimentos/IMG_20200825_162537.jpg';
import pathEight from '../../assets/img/galeria/vias_pavimentos/vias-infraestructura-avacol.jpg';

function ImageList(props) {
  const images = props.images;
  return (
    <>
      {images.map((image) =>
        <Col
          md={6}
          lg={3}
          className="col-12-new col-12"
        >
          <img
            src={image}
            alt={props.alt}
          />
        </Col>
      )}
    </>
  );
}

export const GalleryPathways = () => {
  const images = [
    pathOne,
    pathThree, pathFour,
    pathSix, pathEight,
    pathSeven, pathTwo, pathFive
  ]
  return (
    <>
      <Parallax
        strength={200}
        className="bg-secondary text-center parallax-right py-5 text-white"
      >
        <Background>
          <img
            srcSet={require("../../assets/img/title.jpg")}
            className="w-100"
            style={{height: '445px'}}
            alt=""
          />
        </Background>
        <Container>
          <Row className="py-5">
            <Col>
              <h2 className="display-4 font-weight-bold">Galeria de vías y pavimentos.</h2>
            </Col>
          </Row>
        </Container >
      </Parallax>
      <Container id="gallery">
        <SimpleReactLightBox>
          <SRLWrapper>
            <Row
              className="d-flex flex-wrap align-items-center"
            >
              <ImageList
                images={images}
              />
            </Row>
          </SRLWrapper>
        </SimpleReactLightBox>
      </Container >
    </>
  );
}
